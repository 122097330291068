import LocalStorageManager from './LocalStorageManager.ts';

export * from './utils';
export * from './allStyles';
export * from './admin';
export { colorUtils, colors, allStyles } from './allStyles';
export * from './userUtils';
export * from './load';
export * from './dataUtil';
export * from './math';
export * from './timeUtils';
export * from '../pages/alerts/user.alerts.helpers';
export * from './format.tsx';
export * from './alerts';
export * from './user-settings';
export * from './sandboxData';
export * from '../pages/surveysAndReviews/helpers/index';
export * from '../components/global/input/helpers';
export * from '../pages/crew/helpers/index';
export * from './validation';
export * from './timeline';
export * from './messages';
export * from './url';
export * from './sandbox';

export const storage = new LocalStorageManager();
